@use '../../styles/mixins' as mixins;

.numbered-list-block {

	@include mixins.sectionStyles;
	
	.bnpp-container {
		@include mixins.grid(400px);

		&>div>div.block-editor-block-list__layout {
			@include mixins.grid(400px);
		}

	}

	&.contained {

		.bnpp-container {
			padding: var(--wp--preset--spacing--60);
		}

	}

	&.single {
		.bnpp-container {
			@include mixins.grid(100%);
		}

		.numbered-list-block__content {
			text-align: center;
		}

		.numbered-list-block__list {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			gap: 2em;

			.bnpp-numbered-list-module {
				flex: 0 1 275px;
			}
		}
	}

	h2 {
		padding-top: var(--wp--preset--spacing--50);
	}

	&.dark {
        .numbered-list-block__list {
            .bnpp-numbered-list-module {
				.number {
					background-color: #0A342D;
				}
			}
        }
    }

}